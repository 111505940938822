import type { GetStaticPropsContext } from 'next';
import Link from 'next/link';
// import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import groq from 'groq';
import NextImage from 'next/image';
import type * as Schema from '@fu/studio';

import { usePreviewSubscription } from '@/lib/sanity/sanity';
import { queryBuilder } from '@/lib/sanity/query-builder';
import { filterDataToSingleItem } from '@/utils/sanity-preview';
import type { Locale } from '@/types';

import { FUPortableText } from '@/components/fu-portable-text';
import dynamic from 'next/dynamic';
import clsx from 'clsx';

// import FUHero from '@/components/fu-hero';
// const FUHero = dynamic(() => import('@/components/fu-hero'), {
//   ssr: false,
//   // suspense: true,
// });

const Autocomplete = dynamic(
  () => import('@/components/algolia/autocomplete'),
  {
    suspense: true,
  }
);

type FrontpageData = Omit<Schema.Frontpage, 'pages'> & {
  pages: Array<Schema.LandingPage | Schema.InfoPage>;
  mainImageUrl?: string;
};

type QueryResults = {
  page: Array<FrontpageData>;
  // sections: SectionsData;
};

export async function getStaticProps({
  preview = false,
  locale = 'nb',
}: GetStaticPropsContext) {
  const pageQuery = groq`*[_type == "frontpage"][0] {
    ...,
    pages[]-> {
      ...
    },
    "mainImageUrl": mainImage.asset->{url}.url
  }`;
  const { page: frontpageData, ...settings } = await queryBuilder<QueryResults>(
    {
      page: pageQuery,
    },
    preview,
    { locale }
  );

  // Escape hatch, if our query failed to return data
  // if (!data || (Array.isArray(data.page) && data.page.length === 0)) {
  //   return { notFound: true };
  // }

  // Helper function to reduce all returned documents down to just one
  const page = filterDataToSingleItem(frontpageData, preview);

  const messages = (
    (await import(`@/i18n/${locale}.json`)) as {
      default: IntlMessages | undefined;
    }
  ).default;

  return {
    props: {
      preview,
      data: { page, query: pageQuery, queryParams: {} },
      settings,
      messages,
    },
  };
}

export type FrontpageProps = {
  data: {
    page: FrontpageData;
    query: string;
    queryParams: { slug: string };
  };
  preview: boolean;
};

/**
 * Frontpage.
 */
export function Frontpage({
  data: { query, page: pageData },
  preview,
}: FrontpageProps) {
  const { data: previewData } = usePreviewSubscription(query, {
    params: {},
    initialData: pageData,
    enabled: preview,
  });
  const page = filterDataToSingleItem(previewData, preview);
  const { locale } = useRouter();

  // console.log('previewData', previewData);
  // console.log('preview', preview);
  // console.log('loading', loading);
  // console.log('error', error);

  console.log('@@@@@@@@ page', page);

  return (
    <>
      {/* <FUHero /> */}

      <main className="container relative mx-auto my-16 px-4">
        <article>
          <header className="mb-20 sm:grid sm:grid-cols-12 ">
            <div className="sm:col-span-8">
              <h1 className="hd-2xl text-primary-50 sm:hd-4xl lg:hd-6xl">
                {page?.title}
              </h1>
              <div className="cap-lg">
                {page?.content && page.content[locale as Locale] && (
                  <FUPortableText
                    value={page.content[locale as Locale] as Schema.Content}
                  />
                )}
              </div>
              <div className="my-10 hidden max-w-lg md:block">
                <Autocomplete position="center" />
              </div>
            </div>
            {page.mainImageUrl && (
              <div className="w-full sm:col-start-10 sm:col-end-13">
                <NextImage
                  src={page.mainImageUrl}
                  className="w-full"
                  alt="illustrasjon"
                  loader={({ src }) => src}
                  width="500"
                  height="500"
                />
              </div>
            )}
          </header>

          <section>
            <ul className="grid-cols-3 gap-16 md:grid">
              {Array.isArray(page.pages) &&
                page.pages.map((page, i) => {
                  return (
                    <li
                      key={page._id}
                      className={clsx('mt-10 border-t-6 md:mt-0', {
                        'border-linen-60': i === 0,
                        'border-jasmine-60': i === 1,
                        'border-lavender-60': i === 2,
                      })}
                    >
                      <Link href={`/${page.slug?.current}`}>
                        <h2 className="hd-xl pb-4 pt-6">
                          {page.heading[locale as Locale]}
                        </h2>

                        <p className="body-sm leading-loose">
                          {page.description[locale as Locale]}
                        </p>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </section>
        </article>
      </main>
    </>
  );
}

export default Frontpage;

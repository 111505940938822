import Link from 'next/link';
import { PortableText, PortableTextComponents } from '@portabletext/react';
import type { ArbitraryTypedObject } from '@portabletext/types';

import { Anchor, List, ListItem } from '@fu/ui';
import type * as Schema from '@fu/studio';

export const portableTextComponents: PortableTextComponents = {
  marks: {
    link: ({ children, value }) => {
      const { internal, external } = value as Schema.Link;
      return (
        <Link href={external ?? ''} legacyBehavior passHref>
          <Anchor>{children}</Anchor>
        </Link>
      );
    },
  },
  list: {
    bullet: ({ children }) => <List>{children}</List>,
  },
  listItem: {
    bullet: ({ children }) => <ListItem>{children}</ListItem>,
  },
  block: {
    normal: ({ children }) => (
      <p className="mt-4 whitespace-pre-wrap">{children}</p>
    ),
  },
};

export function FUPortableText({
  value,
  components = {},
}: {
  value: ArbitraryTypedObject[];
  components?: PortableTextComponents;
}) {
  return (
    <PortableText
      value={value}
      components={{ ...portableTextComponents, ...components }}
    />
  );
}

export default FUPortableText;

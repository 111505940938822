/**
 * Helper function to return the correct version of the document
 * If we're in "preview mode" and have multiple documents, return the draft
 */
export function filterDataToSingleItem<T extends { _id: string }>(
  data: T[] | T,
  preview?: boolean
): T {
  if (!Array.isArray(data)) {
    return data;
  }

  if (data.length === 1) {
    return data[0];
  }

  const draft = data.find((item) => item._id.startsWith(`drafts.`));

  if (preview && draft) {
    return draft;
  }

  const notDraft = data.find((item) => !item._id.startsWith(`drafts.`));

  return notDraft || data[0];
}
